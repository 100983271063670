import React, { useState, useEffect, useContext, Component } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/store';
import { toast } from 'react-hot-toast';
import Header from '../components/Header';
import Footer from '../components/Footer';

const CompleteOrder = () => {


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoginFromLocalStorage = localStorage.getItem('token') ? true : false;
  const [isLogin, setIsLogin] = useState(isLoginFromLocalStorage);

  const [isLoginForm, setIsLoginForm] = useState(true); // State to manage which form to display

  const toggleForm = () => {
    setIsLoginForm(prevState => !prevState); // Toggle between login and signup forms
  };


  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);


  return (
    <>
      <Header />

      <div className="py-4" style={{ backgroundColor: "var(--bs-secondary-bg)" }}>
        <div className="container d-lg-flex justify-content-between align-items-center py-2 py-lg-4">
          <div className="pe-lg-4 text-center text-lg-start">
            <h1 className="h3 mb-0">Checkout complete</h1>
          </div>
          <div className="pt-2 pt-lg-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <a className="text-nowrap" href="index.html" previewlistener="true">
                    <i className="ri-store-2-line" /> Home{" "}
                  </a>
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <a href="#">Shop</a>
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  Checkout complete
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container my-4 my-lg-10 py-0 py-sm-4 py-lg-10">
        <div className="card py-4">
          <div className="card-body text-center">
            <h4 className="pb-4">Thank you for your order!</h4>
            <p className="fs-sm">
              Your order <span className="fw-medium">68KV7000N92.</span> has been
              placed and will be processed as soon as possible.
            </p>
            <p className="fs-sm">
              Our manager can contact you by email or phone to confirm the order.
            </p>
            <p className="fs-sm">
              Now you can continue shopping or track your order.
            </p>
            <Link className="btn btn-secondary mt-2 me-4" to="/">
              Continue shopping
            </Link>
            <Link
              className="btn btn-primary d-inline-flex align-items-center mt-2"
              to="/account"
            >
              <i className="ri-map-pin-line me-2" /> Track order{" "}
            </Link>
          </div>
        </div>
      </div>


      <Footer />
    </>
  )
}

export default CompleteOrder
