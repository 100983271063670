import React from 'react';
import { Link, useNavigate } from 'react-router-dom'


const Footer = () => {
    return (
        <>
       
  {/* Footer */}
  <footer
    className="footer"
    style={{ backgroundColor: "var(--bs-accent)" }}
  >
    <div className="footer-top py-4 py-lg-10 border-top">
      <div className="container">
        <div className="row g-4 g-lg-10">
          <div className="col-lg-8">
            <div className="row g-4">
              <div className="col-12 col-md">
                <div className="widget widget-links">
                  <h3 className="widget-title text-white">Quick Access</h3>
                  <ul className="widget-list">
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Accessories
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Smartphones
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Tablets
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Televisions
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Headphones
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Speakers
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Cameras
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Computers
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Notebooks
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Watches
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="widget widget-links">
                  <h3 className="widget-title text-white">Customer Care</h3>
                  <ul className="widget-list">
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="account-login.html">
                        My Account
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="shop-cart.html">
                        My Cart
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="track-order.html">
                        Order Tracking
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <Link className="widget-list-link text-white" to="/wishlist">
                        Wish List
                      </Link>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Customer Support
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        FAQs
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Warranty
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="widget widget-links">
                  <h3 className="widget-title text-white">Information</h3>
                  <ul className="widget-list">
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="about-us.html">
                        About Us
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="contacts.html">
                        Contacts
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Returns &amp; Exchanges
                      </a>
                    </li>
                    <li className="widget-list-item">
                      <a className="widget-list-link text-white" href="#">
                        Shipping &amp; Delivery
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-4">
              <h6 className="mb-2 text-white">Contact Us</h6>
            
            
            </div>
            <div className="mb-4">
              <div className="row align-items-center">
                <div className="col-auto">
                  <i
                    className="text-primary ri-customer-service-2-line"
                    style={{ fontSize: "2.5rem" }}
                  />
                </div>
                <div className="col">
                  <h6 className="mb-0 text-white">24/7 Tech Support</h6>
                  <a href="tel:+2025550135" className="nav-link-base text-white">
                    9876543210
                  </a>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="row">
                <div className="col-auto">
                  <i
                    className="text-primary ri-map-pin-line"
                    style={{ fontSize: "2.5rem" }}
                  />
                </div>
                <div className="col">
                  <h6 className="mb-1 font-weight-bold text-white">Address Info</h6>
                  <span className="text-white">New Delhi</span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="row">
                <div className="col-auto">
                  <i
                    className="text-primary ri-timer-line"
                    style={{ fontSize: "2.5rem" }}
                  />
                </div>
                <div className="col">
                  <h6 className="mb-1 font-weight-bold text-white">Working Hours</h6>
                  <span className="d-block text-white">Mon - Fri: 9AM - 6PM</span>
                  <span className="d-block text-white">Sat: 10AM - 3PM</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom border-top py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-3 text-center text-lg-start">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a className="link-accent fs-xl" href="#">
                  <i className="ri-facebook-line" />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="link-danger fs-xl" href="#">
                  <i className="ri-google-line" />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="link-info fs-xl" href="#">
                  <i className="ri-twitter-line" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <a href="https://seotowebdesign.com/" className="nav-link-base fs-sm text-white" >
              © 2024 - Cayroshop.com All rights reserved by Seo To Webdesign
            </a>
          </div>

          {/* <div className="col-12 col-lg-3 text-center text-lg-end mt-3 mt-lg-0">
            <img
              className="d-inline-block align-middle"
              src="assets/img/shop/cards.png"
              width={246}
              alt="Cerdit Cards"
            />
          </div> */}


        </div>
      </div>
    </div>
  </footer>
</>



    )
}

export default Footer